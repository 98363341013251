@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;600;700;800&display=swap');

@media (max-width: 768px) {
    .NotFound{
        display:flex;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat', sans-serif;
        flex-direction: column;
        height: 100vh;
    }

    .NotFoundContent{
        max-width: 80vw;
        text-align: center;
    }

    .NotFoundTitle{
        color: #1B2E35;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.3px;
    }

    .NotFoundSubtitle{
        color: #2A454E;
        padding: 7px 10vw 0px 10vw;
        text-wrap: pretty;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.2px;
    }
}

@media (min-width: 768px) {
    .NotFound{
        display:flex;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat', sans-serif;
        flex-direction: column;
        height: 100vh;
    }

    .NotFoundContent{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .NotFoundTitle{
        color: #1B2E35;
        text-align: center;

        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.88px;
    }

    .NotFoundSubtitle{
        color: #2A454E;
        text-align: center;
        font-size: 21px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.8px;
    }
}