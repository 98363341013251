@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;600;700;800&display=swap');

.Terms{
    padding: 0px 10px 0px 10px;
    font-family: 'montserrat', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
}

.TermsHeadings{
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top:100px;
}

.TermsHeading{
    font-weight: 700;
    font-size: 40px;
    text-shadow: 3px 3px 4px #787878;
    color: #6d6d6d;
}

.TermsSubHeading{
    font-weight: 600;
    font-size: 20px;
    color: #787878;
}

.TermsContentHeading{
    font-weight: 600;
    font-size: 22px;
}

.TermsContentInfo{
    font-weight: 600;
    padding-left: 10px;
    color: #6d6d6d;
}

.TermsContentFooter{
    text-align: center;
    font-weight: 600;
    padding-left: 10px;
    color: #6d6d6d;
    text-wrap: pretty;
}