@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;600;700;800&display=swap');

ul, li{
    list-style-type: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

@media (max-width: 767px) {
    .Home{
        font-family: 'Montserrat', sans-serif;
        height: 120%;
        background: linear-gradient(110deg, hsla(242, 58%, 73%, 1) 0%, hsla(157, 72%, 82%, 1) 100%);
        background-size: 200% 400%;
        animation: gradient 10s ease infinite;
    }

    @keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
    }

    .HomeHeader{
        top:0;
        left:0;
        right: 0;
        width: 100% !important;
        display: inline-flex;
        align-items: center;
    }

    .HMHEaderProjectName{
        clear: left;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        z-index: 19;
        padding: 10px;
        color: #FFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.4px;
    }

    .HMHeaderDemoName{
        clear: right !important;
        margin-left: auto;
        display: none;
        display: flex;
        justify-content: right;
        align-items: right;
        gap: 14px;
        padding: 10px;
        color: #FFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.4px;
    }

    .HomeHeadingContainerWrapper{
        padding: 10px;
    }

    .HomeHeadingContainer{
        background-color: #f2f2f2;
        border-radius: 15px;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .HomeHeading{
        color: #000;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.48px;
        text-align: center;
    }

    .HomeSubheading{
        color: #626262;
        text-align: center;
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.44px;
        text-align: center;
    }

    .HMFeaturesListDescription{
        padding-left: 10px !important;
    }

    .HMFeaturesContent{
        padding-top: 15px;
    }

    .HMBtns{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding-top: 20px;
    }

    .HMInquire{
        text-decoration: none;
        background-color: #54B886;
        border-radius: 20px;
        padding: 5px 35px 5px 35px;
        color:#0B0B0B;
        font-size: 20px;
        font-weight: 700;
    }

    .HMDemoProject{
        text-decoration: none;
        background-color: #54b88616;
        border: #0B0B0B 3px solid;
        border-radius: 20px;
        padding: 2px 32px 2px 32px;
        color:#0B0B0B;
        font-size: 20px;
        font-weight: 700;
    }

    .HMDemoProject:active{
        color: #ffffff;
        background: #0B0B0B;
    }

    .HMInquire:active{
        color:#ffffff;
    }

    .HMThumbnail{
        display: none;
        visibility: hidden;
    }
}

@media (min-width: 768px) {
    .Home{
        font-family: 'Montserrat', sans-serif;
        min-height: 100vh;
        height: 100%;
        background: linear-gradient(90deg, hsla(242, 58%, 73%, 1) 0%, hsla(157, 72%, 82%, 1) 100%);
        background-size: 200%;
        animation: gradient 10s ease infinite;
    }

    @keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
    }

    .HomeHeader{
        top:0;
        left:0;
        right: 0;
        width: 100% !important;
        display: inline-flex;
        align-items: center;
    }

    .HMHEaderProjectName{
        clear: left;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        z-index: 19;
        padding: 10px;
        color: #FFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Montserrat;
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.4px;
    }

    .HMHeaderDemoName{
        clear: right !important;
        margin-left: auto;
        display: none;
        display: flex;
        justify-content: right;
        align-items: right;
        gap: 14px;
        padding: 10px;
        color: #FFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.4px;
    }

    .HomeHeadingContainerWrapper{
        padding-left: 5vw;
        padding-top: 5vh;
        max-width: 60rem;
    }

    .HomeHeadingContainer{
        display: flex;
        flex-direction: column;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        border-radius: 20px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        padding: 20px;
    }

    .HomeHeading{
        text-align: center;
        color: #353535;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 56px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 1.4px;
        text-decoration-line: underline;
    }

    .HomeSubheading{
        color: #353535;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.84px;
    }

    .HMFeaturesContent{
        display:flex;
        flex-direction: column;
        padding: 0px 15px 0px 15px !important;
    }

    .HMFeaturesList{
        font-size: 20px;
        font-weight: 700;
    }

    .HMFeaturesListDescription{
        padding: 0px 35px 0px 15px !important;
        font-weight: 600;
        text-wrap: balanced;
    }

    .HMBtns{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
        padding-top: 20px;
    }

    .HMInquire{
        cursor: pointer;
        text-decoration: none;
        background-color: #54B886;
        border-radius: 25px;
        padding: 5px 35px 5px 35px;
        color:#0B0B0B;
        font-size: 30px;
        font-weight: 700;
    }

    .HMDemoProject{
        cursor: pointer;
        text-decoration: none;
        background-color: #54b88616;
        border: #0B0B0B 3px solid;
        border-radius: 25px;
        padding: 2px 32px 2px 32px;
        color:#0B0B0B;
        font-size: 30px;
        font-weight: 700;
    }

    .HMInquire:hover{
        color: #ffffff;
        transition: .2s ease-in;
    }

    .HMDemoProject:hover{
        color: #ffffff;
        background: #0B0B0B;
        transition: .2s ease-in;
    }

    .HMInquire:active{
        color:#ffffff;
    }
}

@media (min-width: 768px) and (max-width: 1879px) {
    .Home{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .HMThumbnailConatiner{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 100px;
        padding-bottom: 50px;
        width: 70vw;
        aspect-ratio: auto;
    }

    .HMThumbnail{
        width: 1200px;
        aspect-ratio: auto;
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
    }

    .HomeHeadingContainerWrapper{
        position: relative;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

@media (min-width: 1879px){
    .HMThumbnailConatiner{
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 20px;
        max-width: 50vw;
    }

    .HMThumbnail{
        width: 1200px;
        aspect-ratio: auto;
        border-radius: 20px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
}