@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;600;700;800&display=swap');

@media (max-width: 767px) {
.App {
    font-family: 'Montserrat', sans-serif;
}

.read-only-banner{
    position: fixed;
    top: 0;
    text-align: center;
    width: 100%;
    z-index:99;
}

.dropdownAvatar{
    width:50%;
}

.headerContainer {
    z-index: 20;
    position: fixed !important;
    padding: 10px 15px 10px 20px;
    top:0;
    left:0;
    right: 0;
    width: 100% !important;
    display: inline-flex;
    align-items: center;
}

.headerProjectName {
    clear: left;
    color: #898989;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    z-index: 19;
}

.userHeading {
    clear: right !important;
    margin-left: auto;
    display: none;
    display: flex;
    justify-content: right;
    align-items: right;
    gap: 14px;
    cursor: pointer;
}

.avatarImg {
    width: 50px;
    border-radius: 15px;
}

.dropdownAvatar {
    width: 100%;
}

.userAvatarDropdownActiveWrapper{
    left: 0;
    z-index: 99;
    width: 100vw;
    position: fixed;
    bottom: 0;
    background: #F1F1F1;
    border-radius: 40px 40px 0px 0px;
}

.userAvatarDropdownActive{
    height: fit-content;
    padding: 20px 10px 30px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.songDropdownActiveWrapper{
    left: 0;
    z-index: 99;
    width: 100vw;
    position: fixed;
    bottom: 0;
    background: #F1F1F1;
    border-radius: 40px 40px 0px 0px;
}

.DAlikeFieldBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    background: #E8E8E8;
    font-size: 20px;
}

.PLSongLikeImg{
    height: auto;
}

.SearchAddInfo{
    margin-left: auto;
    flex-wrap: nowrap;
    height: auto;
    min-width: 30px;
    max-width: 30px;
    overflow: hidden;
}

.DAlikeField{
    display: flex;
    gap: 7px;
}

.DASongLikeCount{
    font-weight: 600;
}

.PLactionDropwdownWrapper{
    left: 0;
    z-index: 99;
    width: 100vw;
    position: fixed;
    bottom: 0;
    border-radius: 40px 40px 0px 0px;
    background: rgba(241, 241, 241, 0.8)
    }

.PLactionDropdown{
    background: rgba( 241, 241, 241, 0.8 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 5.5px );
    -webkit-backdrop-filter: blur( 5.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    border-radius: 40px 40px 0px 0px;
    padding: 20px 10px 30px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.DAHeading, .PLtypeInfo{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #757575;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.DAuserName, .PLsongInfo{
    text-align: center;
    width: 85vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.DAdivider{
    width: 200px;
    height: 10px;
    filter: invert(75%);
}

.DAcontent{
    padding-left: 2px;
    flex-grow: 1;
}

.DAcontentItem{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: underline;
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    gap: 50px;
}

.DAcontentItem:visited{
    color: #0C0C0C;
}

.PLdivider2{
    filter: invert(75%);
    margin-bottom: 10px;
}

.PLactionBtnItem{
    text-wrap: nowrap;
    justify-content: center;
    min-width: 55vw;
    display: flex;
    border-radius: 10px;
    background: #dadada9f;
    padding: 10px;
    text-decoration: none;
    color: #0C0C0C;
    font-size: 24px;
    font-weight: 700;
}

.PLactionBtnItemTxT{
    text-decoration: none;
    position: relative;
    color: #0C0C0C;
}

.PLactionBtnIcon{
    display: inline-block;
    padding-right: 10px;
}


.heading1{
    text-align: center;
    margin-top: 100px;
    font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
    font-weight: 800;
    font-size: 36px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    color: #898989;
}

.heading2small{
    color: #B5B5B5;
    text-align: center;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* BEGIN INPUTBAR*/
.inputBar {
    padding: 0px !important;
    margin-top: 15px;
    display: flex;
    align-items: center;
    position: relative;
    width: 95vw;
}

.inputThing{
    color: var(--primary-text-color);
    font-size: 20px;
    font-weight: 600;
	padding: 8px 10px 8px 10px;
	width: 100%;
	padding-left: 50px;
	outline: none;
	background: #D9D9D9;
	color: #000000;
	border: 2px solid var(--box-sc-bg-color);
	border-radius: 15px;
	/*box-shadow: 3px 3px 2px 0px #E2E2E2;*/
	transition: .3s ease;
}

.inputThing::placeholder{
    color: #606060;
}

.searchBtn{
    position: absolute;
	left: 20px;
	fill: #000000;
	width: 20px;
	height: 20px;
}

.exitBtnSBhidden{
    position: absolute;
	right: 30px;
	fill: #000000;
	width: 20px;
	height: 20px;
    visibility: hidden !important;

}

.exitBtnSBvisible{
    position: absolute;
	right: 20px;
	fill: #000000;
	width: 20px;
	height: 20px;
    visibility: visible;
}

.SearchResults{
    padding: 0px !important;
    background-color: var(--box-sc-bg-color);
    border-radius: 15px;
    width: 95vw;
    margin-top: -25px !important;
    z-index: -1;
}

.hiddenBX{
    visibility: none !important;
    display: none !important;
}

.SearchResultItems{
    padding-top: 25px;
    padding-left:10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 0px;
}

.SearchResultActive{
    display:block !important;
}

.SBError{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.SBErrorMsg{
    font-size: 18px;
    font-weight: 600;
    color: #C04848;
    text-align: center;
    text-wrap: balance;
}

.SBErrorSecondary{
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    text-wrap: balance;
}

.SBResult{
    display: flex !important;
    align-items: center;
    padding: 10px;
    border-radius: 30px;
    /*background: rgba(238, 238, 238, 0.70);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
    z-index: 10;
    min-width: 0;
    gap: 10px;
}

.SBResultInfo{
    display: flex;
    min-width: 0;
    align-items: center;
    gap: 10px;
}

.SBImageHelper{
   display: flex !important;
   justify-content: center !important;
   align-items: center;
   flex-shrink: 0;
}

.PLPreview{
    display: flex;
    justify-content: center;
    align-items: center;
}

.SearchBtnHelper{
    display: flex;
    justify-content: center;
}

.PLPreviewPlayer{
    position: absolute;
    z-index: 9;
    cursor: pointer;
}

.PLImagePreviewPlayer{
    width: 35px;
    height: 35px;
}


.addSongBtn{
    float: right;
    margin-left: auto !important;
    background-color: #54B886;
    border-radius: 7px;
    padding:8px;
    padding-left: 20px;
    padding-right: 20px;
    color: #f7f7f7;
    font-weight: 700;
    font-size: 18px;
}

.addSongBtn .select{
    background-color: #0C0C0C !important;
   
}

.select {
    background-color: #0C0C0C !important;
    color: #FFF;
    border-style: none !important;
    padding:12px;
}

.addSongBtn:hover{
    transition: all 0.2s ease-in-out;
    background-color: #bcbcbc;
    box-shadow: 0px 0px 15px rgb(169, 169, 169);
}

.SBRBorderHelper:hover{
    transition: all 0.2s ease-in-out;

}

.SBResultImage {
    border-radius: 10px;
    position: relative;
    height: 60px;
    width: 60px;
}

.SBResultText{

    flex-grow: 1;
    overflow: hidden;
}

.SBResultTitle {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #7F7F7F;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;

}

.SBResultArtist{
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
    color: #989898;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
    margin-top: -10px;
}

.SBResultArtistAdded{
    color: #4F4F4F;
    font-weight: 800;
}

.loadMoreFooter{
    width: 100%;
    padding: 15px !important;
    cursor: pointer !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, var(--box-sc-bg-color) 0%, #d3d3d3 5%) ;
    border-radius: 0px 0px 15px 15px;
}


.lMImgHelper{
    display: none;
    visibility: hidden;
}

.loadMoreTxt{
    text-decoration: underline;
    text-align: left;
    padding-left: 0px !important;
    color: #555555;
    font-size:17px;
    font-weight: 700;
}

.sortIconBtn{
    display: inline;
}

.Sort-Options{
    padding: 0px !important;
    border-radius: 15px;
    width: 90vw;
    margin-top: 10px;
    z-index: -1;
}

.SortbyLabel{
    display: inline;
    padding-left: 10px;
    font-weight: bold;
    font-size: 14px;
}

.Dd-So{
    background-color: #dedede;
    border-width: 0 !important;
    border-style:none !important;
    color:#aeaeae !important;
    display: inline-block !important;
}

.sortIconBtn{
    display: inline-block !important;
}

.Dd-So:hover {
    transition: all 0.2s ease-in-out;
    color: #666666 !important;
}

.Dd-So:hover > .SortbyLabel {
    transition: all 0.2s ease-in-out;
    color: #666666;
}

.PlaylistItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    margin-bottom: 20px;
    margin-top: 7px;
}

.PlaylistItem {
    width: 90vw;
    display: flex !important;
    align-items: center;
    padding: 10px;
    border-radius: 21px;
    background: #F0F0F0;
    /*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); rgba(238, 238, 238, 0.70)*/
    z-index: 10;
    min-width: 0;
    margin-top: 12px;
}

.PLImageHelper{
    height: 60px;
    width: 60px;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    flex-shrink: 0;
    background-color: #999999;
    border-radius: 15px;
}

.PlaylistItemInfo{
    display: flex;
    min-width: 0;
    align-items: center;
    gap: 12px;
}

.PLSongInfo{
    margin-top: 5px !important;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.PLSTitle{
    color: #595959;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.48px;
}

.PLArtist{
    display: inline-block;
    color: #B5B5B5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--primary-text-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.36px;
}

.PLImage {
    border-radius: 11px;
    height: 60px;
    width: 60px;
}

.PLSongLikeField{
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: 15px;
}

.PLSongAddInfo{
    flex-shrink: 0;
}

.StickyFooter{
    padding-top: 20px;
    display: grid;
    z-index: 10;
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: 0px;
    position: sticky;  
    bottom: 0;
    width: 100%;
    left:0;
    margin-bottom: 10px;
    background: linear-gradient(180deg, transparent 0%, rgba(255, 255, 255, 0.7) 50%, #FFF 100%);
    white-space: nowrap;
}

.StickyFooterBtnWrapper{
    grid-area:  1 / 2 / 2 / 3;
    display: flex;
    justify-content: center;
}

.StickyFooterVersionTxt{
        display: none;
        visibility: hidden;
}

.StickyFooterFadeOut{
    background-image: linear-gradient(to bottom, transparent, rgb(255, 255, 255));
    height:20%;
}

.DELBackdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 101;
    background: rgba(0, 0, 0, 0.70);
    backdrop-filter: blur(4px);
}

.DelAlertWrapper{
    position: fixed;
    display: flex;
    top: 0;
    justify-content: center;
    align-items: center;
    z-index: 102;
    width: 100%;
    height: 100vh;
}

.DelAlert{
    max-width: 70vw;
    z-index: 19;
    background-color: #FFF;
    border-radius: 20px;
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.DELHeading{
    font-size: 26px;
    font-weight: 600;
}

.DELContent{
    display: flex;
    flex-direction: column;
}

.DELInfo {
    font-size: 20px;
    font-weight: 700;
}

.DELLink{
    color: #808080;
    font-size: 20px;
    font-weight: 600;
    text-decoration: 2px underline;
}

.DELLink:hover{
    color:#0C0C0C;
    transition: all 0.3s ease-out;
}

.DELUnderstandWrapper{
    display: flex;
    justify-content: center;
    padding: 10px 0px 5px 0px;
}

.DELUnderstandBtn{
    font-size: 20px;
    font-weight: 700;
    border: #0C0C0C 3px solid;
    border-radius: 30px;
    background-color: #FFF;
    padding: 5px 20px 5px 20px;
}

.DELUnderstandBtn:hover{
    color: #FFF;
    background-color: #0C0C0C; 
    transition: all 0.3s ease-in-out;
}

.pagination{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;
}

.PA-Heading{
    color: #7F7F7F;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.PA-Heading-highlight{
    color: #484848;
    font-weight: 600;
}

.PA-Nav{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:15px;
    border-radius: 40px;
    background: rgba(84, 184, 134, 0.21);
    padding: 10px 20px 10px 20px;
}

.PA-content{
    color: #222;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    text-wrap: nowrap;
}

.PA-firstNums{
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    text-wrap: nowrap;
}

.PA-lastNum{
    cursor: pointer;
}

.activePage{
    color: #0DA955;
}

.PA-arrow{
    filter: invert(40%);
    width: 15px;
}

.activeArrow{
    filter: invert(0%);
    cursor: pointer;
}


.OpenInSpotify{
    content: 'Spotify Playlist' !important;
    border:none;
    border-radius: 10px;
    background-color: #46A877;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    font-size: 26px;
    font-weight: 700;
    padding: 8px 20px 8px 20px;
    margin: 20px 0px 20px 0px;
    cursor: pointer;
    text-decoration: none;
    color:#2b2b2b;
    color: #0B0B0B;
}

.OpenInSpotify:hover{
    transition: all 0.2s ease-in-out;
    color:#ffffff;
    background-color: #2faf6f;
    border-color: #2faf6f;
    box-shadow: 2px 2px 15px rgba(107, 183, 98, 0.74);
}

.webInfoFooter{
    background-color: #717171;
}

.footerContentMain{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1.5fr 1fr;
    gap: 10px;
    padding: 10px 15px 20px 15px;
}

.footerCompanySocialsFooter{
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerCompanyInfos{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.footerCompanyName{
    text-wrap: pretty;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.footerProblemReportLink{
    color: #adadad;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-decoration-line: underline;
}


.footerProblemReportLink:hover{
    color:#FFF;
    transition: all .2s ease-in;
}


.footerLegalInfo{
    text-decoration: underline;
    color: #b4b4b4;
    display:flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 5px;
    word-break: keep-all;
}

.footerLegalInfoItem{
    position: relative;
    text-decoration: none;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.footerLegalInfoItem:active{
    color: #0C0C0C;
}

.footerLegalInfoItem:hover{
    color: #0C0C0C;
}

.footerCompanySocials{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.footerCompanyMailHeading{
    margin-right: auto;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.footerCompanyMail{
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.footerCompanySocialsDivider{
    display: none;
}

.footerSocialInfo{
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.footerSocialIcon{
    width: 20px;
}

.footerSocialInfoItem:hover{
    filter: invert(100%);
    transition: all .2s ease-in-out;
}

.footerDescription{
    position: absolute;
    bottom: 0;
    padding:20px;
    color: #212121;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
}
}

@media (min-width: 768px) {
.App {
    font-family: 'Montserrat', 'sans-serif';
}

.headerContainer {
    z-index: 19;
    position: fixed !important;
    padding: 10px 40px 10px 40px;
    top:0;
    left:0;
    right: 0;
    width: 100% !important;
    display: inline-flex;
    align-items: center;
}

.headerProjectName {
    clear: left;
    color: #898989;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    z-index: 19;
}

.userHeading {
    clear: right !important;
    margin-left: auto;
    display: none;
    display: flex;
    justify-content: right;
    align-items: right;
    gap: 14px;
    cursor: pointer;
}

.dropdownAvatar {
    width: 100%;
}

.avatarImg {
    width: 60px;
    border-radius: 15px;
}

.userAvatarDropdownActiveWrapper{
    position: fixed;
    top: 80px;
    right: 20px;
    z-index: 19;
}

.songDropdownActiveWrapper{
    position: absolute;
    left: 45%;
    z-index: 19;
}

.DAlikeFieldBtn{
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    background: #E8E8E8;
    font-size: 20px;
    cursor: pointer;
}

.PLSongLikeImg{
    height: auto;
}

.SearchAddInfo{
    margin-left: auto;
    flex-wrap: nowrap;
    height: auto;
    min-width: 30px;
    overflow: hidden;
}

.DAlikeField{
    display: flex;
    gap: 7px;
}

.DASongLikeCount{
    font-weight: 600;
}

.userAvatarDropdownActive{
    border-radius: 15px;
    background-color: #d7d7d7;
    padding: 10px 20px;
    max-width: 220px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px;
}

.DAHeading{
    color: #757575;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.DAuserName{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.DAdivider{
    filter: invert(50%);
}

.DAcontent{
    padding-left: 2px;
    display: flex;

    justify-content: center;
    flex-direction: column;
}

.DAcontentItem{
    text-decoration: none;
    position: relative;
    text-wrap: nowrap;
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.DAcontentItem:visited{
    color: #0C0C0C;
}

.DAcontentItem::before{
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background-color: #0C0C0C;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.3s ease;  
}

.DAcontentItem:hover{
    color: #0C0C0C;
    transition: all .2s ease-in;
}

.DAcontentItem:hover::before{
    transform: scaleX(1);
}

.DELBackdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 80;
    background: rgba(0, 0, 0, 0.70);
    backdrop-filter: blur(2px);
}

.DelAlertWrapper{
    position: fixed;
    display: flex;
    top: 0;
    justify-content: center;
    align-items: center;
    z-index: 100;
    width: 100%;
    height: 100vh;
}

.DelAlert{
    max-width: 30rem;
    z-index: 19;
    background-color: #FFF;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.DELHeading{
    font-size: 26px;
    font-weight: 600;
}

.DELLink{
    color: #555555;
    font-weight: 600;
    text-decoration: 2px underline;
}

.DELLink:hover{
    color:#0C0C0C;
    transition: all 0.3s ease-out;
}

.DELUnderstandWrapper{
    display: flex;
    justify-content: center;
    padding: 10px 0px 5px 0px;
}

.DELUnderstandBtn{
    border: #0C0C0C 3px solid;
    border-radius: 10px;
    background-color: #FFF;
    padding: 5px 10px 5px 10px;
}

.DELUnderstandBtn:hover{
    color: #FFF;
    background-color: #0C0C0C; 
    transition: all 0.3s ease-in-out;
}

.heading1{
    text-align: center;
    margin-top:100px;
    font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
    font-weight: 700;
    font-size: 70px;
    text-shadow: 3px 3px 4px #787878;
    color: #6d6d6d;
}

.heading2small{
    color: #B5B5B5;  
    text-align: center;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.inputHeader {
    z-index: 11;
    display: flex;
    justify-content: center;
}

/* BEGIN INPUTBAR*/
.inputBar {
    padding: 0px !important;
    margin-top: 15px;
    display: flex;
    align-items: center;
    position: relative;
    max-width: 60rem;
}

.inputThing{
    color: var(--primary-text-color);
    font-size: 32px;
    font-weight: 600;
	padding: 18px 20px 18px 20px;
	width: 100%;
	padding-left: 80px;
	outline: none;
	background: #D9D9D9;
	color: #000000;
	border: 2px solid var(--box-sc-bg-color);
	border-radius: 15px;
	/*box-shadow: 3px 3px 2px 0px #E2E2E2;*/
	transition: .3s ease;
}

.inputThing::placeholder{
    color: #606060;
}

.searchBtn{
    position: absolute;
	left: 30px;
	fill: #000000;
	width: 40px;
	height: 40px;
}

.exitBtnSBhidden{
    position: absolute;
	right: 30px;
	fill: #000000;
	width: 40px;
	height: 40px;
    visibility: hidden !important;

}

.exitBtnSBvisible{
    position: absolute;
	right: 30px;
	fill: #000000;
	width: 40px;
	height: 40px;
    visibility: visible;
}

.Sort-Options{
    padding: 0px !important;
    border-radius: 15px;
    max-width: 60rem;
    margin-top: 10px;
    z-index: -1;
}

.SortbyLabel{
    display: inline;
    padding-left: 10px;
    font-weight: bold;
    font-size: 18px;
    color: #8e8e8e;
}

.Dd-So{
    background-color: var(--background-color) !important;
    border-width: 0 !important;
    border-style:none !important;
    color:#aeaeae !important;
    display: inline-block !important;
}

.sortIconBtn{
    display: inline-block !important;
}

.Dd-So:hover {
    transition: all 0.2s ease-in-out;
    color: #666666 !important;
}

.Dd-So:hover > .SortbyLabel {
    transition: all 0.2s ease-in-out;
    color: #666666;
}

.dropdown-Container{
    border-width: 2px !important;
}

.dropdown-txt {
    font-weight: 600 !important;
    color: #828282 !important;
}

.dropdown-txt:hover{
    transition: all 0.2s ease-in-out;
    color: #555555!important;
    text-shadow: 1px 1px 2px #b6b6b6;
    background-color: #f2f2f2 !important;
}

.SearchResults{
    padding: 0px !important;
    background-color: var(--box-sc-bg-color);
    border-radius: 15px;
    max-width: 60rem;
    margin-top: -25px !important;
    z-index: -1;
}

.hiddenBX{
    visibility: none !important;
    display: none !important;
}

.SearchResultItems{
    padding-top: 25px !important;
    padding-left:50px !important;
    padding-right: 50px !important;
    padding-bottom: 10px !important;
    margin-bottom: 0px !important;
}

.SearchResultActive{
    display:block !important;
}

.SBError{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.SBErrorMsg{
    font-size: 20px;
    font-weight: 600;
    color: #C04848;
    text-align: center;
    text-wrap: balance;
}

.SBErrorSecondary{
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    text-wrap: balance;
}

.SBResultWrapper{
    padding-top: 15px !important;
}

.SBResult{
    display: flex !important;
    align-items: center;
    padding: 10px;
    border-radius: 30px;
    background: rgba(238, 238, 238, 0.70);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 10;
    min-width: 0;
}

.SBResultInfo{
    display: flex;
    min-width: 0;
    align-items: center;
    gap: 15px;
}

.SBImageHelper{
   display: flex !important;
   justify-content: center !important;
   align-items: center;
   flex-shrink: 0;
}

.PLPreview{
    display: flex;
    justify-content: center;
    align-items: center;
}

.SearchBtnHelper{
    display: flex;
    justify-content: center;
}

.PLPreviewPlayer{
    position: absolute;
    z-index: 9;
    cursor: pointer;
}

.addSongBtn{
    float: right;
    margin-left: auto !important;
    background-color: #d1d1d1;
    box-shadow: 1px 1px 10px rgb(255, 255, 255);
    border-radius: 10px;
    border: 4px solid !important;
    padding:8px;
    padding-left: 20px;
    padding-right: 20px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size:20px;
}

.addSongBtn .select{
    background-color: #0C0C0C !important;
   
}

.select {
    background-color: #0C0C0C !important;
    color: #FFF;
    border-style: none !important;
    padding:12px;
}

.addSongBtn:hover{
    transition: all 0.2s ease-in-out;
    background-color: #bcbcbc;
    box-shadow: 0px 0px 15px rgb(169, 169, 169);
}

.SBRBorderHelper:hover{
    transition: all 0.2s ease-in-out;

}

.SBResultImage {
    border-radius: 20px;
    position: relative;
    height: 70px;
    width: 70px;
}

.SBResultText{
    margin-top: 5px !important;
    flex-grow: 1;
    overflow: hidden;
}

.SBResultTitle {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #7F7F7F;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;

}

.SBResultArtist{
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
    color: #989898;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.24px;
}

.SBResultArtistAdded{
    color: #4F4F4F;
    font-weight: 800;
}

.loadMoreFooter{

   padding: 15px !important;
   cursor: pointer !important;
}


.lMImgHelper{
    padding-left: 10px;
    padding-right: 5px !important;  
    display: inline-block !important;
    justify-content: center;
    align-items: center !important;
}

.loadMoreTxt{
    display: inline-block !important;
    text-align: left;
    padding-left: 10px !important;
    color: #959595;
    font-size:17px;
    font-weight: 500;
}

.loadMoreFooter:hover{
    color:#2faf6f;
}


.PlaylistItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    margin-bottom: 30px;
}

.PlaylistItem {
    width: 40rem;
    display: flex !important;
    align-items: center;
    padding: 10px;
    border-radius: 30px;
    background: rgba(238, 238, 238, 0.70);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 10;
    min-width: 0;
    margin-top: 20px;
}

.PLImageHelper{
    height: 90px;
    width: 90px;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    flex-shrink: 0;
    background: linear-gradient(200deg, #9b9b9b, #7c7c7c);
    border-radius: 15px;
}

.PlaylistItemInfo{
    display: flex;
    min-width: 0;
    align-items: center;
    gap: 15px;
}

.PLSongInfo{
    margin-top: 5px !important;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.PLSTitle{
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.48px;
}

.PLArtist{
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--primary-text-color);
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.36px;
}

.PLImage {
    color: #ffffff;
    border-radius: 15px;
    height: 90px;
    width: 90px;
}

.PLSongLikeField{
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: 15px;
    cursor: pointer;
}

.PLSongAddInfo{
    flex-shrink: 0;
}

.PLactionDropwdownWrapper{
    max-width: 200px;
    margin-top: 10px;
    margin-left: -100px;
    position: absolute;
    z-index: 99;
}

.PLactionDropdown{
    background-color: #FFF;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.PLsongInfo{
    padding: 0px 10px 0px 10px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}

.PLdivider2{
    filter: invert(75%);
}

.PLactionBtnItem{
    display: inline-block;
    text-decoration: none;
    color: #0C0C0C;
    cursor: pointer;
}

.PLactionBtnItemTxT{
    text-decoration: none;
    position: relative;
    color: #0C0C0C;
}

.PLactionBtnItemTxT::before{
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    padding-top: 2px;
    background-color: #0C0C0C;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.3s ease;  
}

.PLactionBtnItemTxT:hover{
    color: #0C0C0C;
    transition: all .2s ease-in;
}

.PLactionBtnItemTxT:hover::before{
    transform: scaleX(1);
}

.PLactionBtnIcon{
    display: inline-block;
    padding-right: 10px;
}

.PLtypeInfo{
    display: none;
}

.btn-Search {
    margin-left: -50px;
    width: 7rem;
    border-radius: 10px;
    border-width: 1px;
    background-color: #54B886;
    border-color: #54B886;
    margin-bottom: -0.1px;
    border-style: solid;
}

.btn-Search:hover {
    transition: all 0.1s ease-in-out;
    background-color: #2faf6f;
    border-color: #2faf6f;
    box-shadow: 0 5px 15px rgba(107, 183, 98, 0.4);
}

.btn-Search:hover > .searchBtnTxt {
    transition: all 0.2s ease-in;
    color: #ffffff;
    text-shadow: 1px 1px 2px #aeaeae;
}

.searchBtnTxt:hover{
    color: #2b2b2b;
}

.pagination{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;
}

.PA-Heading{
    color: #7F7F7F;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.PA-Heading-highlight{
    color: #484848;
    font-weight: 600;
}

.PA-Nav{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:15px;
    border-radius: 40px;
    background: rgba(84, 184, 134, 0.21);
    padding: 10px 20px 10px 20px;
}

.PA-content{
    color: #222;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    text-wrap: nowrap;
}

.PA-firstNums{
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    text-wrap: nowrap;
}

.PA-lastNum{
    cursor: pointer;
}

.activePage{
    color: #0DA955;
}

.PA-arrow{
    filter: invert(40%);
    width: 15px;
}

.activeArrow{
    filter: invert(0%);
    cursor: pointer;
}

.StickyFooter{
    padding-top: 20px;
    display: grid;
    z-index: 10;
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: 0px;
    position: sticky;  
    bottom: 0;
    width: 100%;
    left:0;
    margin-bottom: 10px;
    background: linear-gradient(180deg, transparent 0%, rgba(227, 227, 227, 0.70) 40%, var(--background-color) 100%);
    white-space: nowrap;
}

.StickyFooterBackground{
    color: #999999;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
    content: '';
    position: absolute;
    inset: 10%;
    backdrop-filter: blur(2px);
    -webkit-mask: linear-gradient(180deg, transparent 0%, rgba(227, 227, 227, 0.70) 70%);
    background: linear-gradient(180deg, transparent 0%, rgba(227, 227, 227, 0.70) 60%, var(--background-color) 100%);
}

.StickyFooterBtnWrapper{
    grid-area:  1 / 2 / 2 / 3;
    display: flex;
    justify-content: center;
}

@media (max-width: 1023px){
    .StickyFooterVersionTxt{
        display: none;
        visibility: hidden;
    }
}

@media (min-width: 1024px){
    .StickyFooterVersionTxt{
        grid-area: 1 / 3 / 2 / 4;
        display: flex;
        align-items: center;
        margin-left: auto;
        cursor: pointer;
        color: #898989;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-right: 10px;
    }
}


.StickyFooterFadeOut{
    background-image: linear-gradient(to bottom, transparent, rgb(255, 255, 255));
    height:20%;
}

.webInfoFooter{
    margin-top: 100px;
}

.backgroundSVGCurve{
    aspect-ratio: 960/300;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background: linear-gradient(129deg, #54B8A6 35.01%, #2ed3709f 97.07%);
    backdrop-filter: blur(2px);
    position: relative;
}

.footerContent{
    padding-top: 300px;
}



.OpenInSpotify{
    border:none;
    border-radius: 15px;
    background-color: #54B886;
    font-size: 30px;
    font-weight: 800;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    margin: 20px 0px 20px 0px;
    cursor: pointer;
    text-decoration: none;
    color:#2b2b2b;
    padding-left: 30px;
    padding-right: 30px;
}

.OpenInSpotify:hover{
    transition: all 0.2s ease-in-out;
    color:#ffffff;
    background-color: #2faf6f;
    border-color: #2faf6f;
    box-shadow: 2px 2px 15px rgba(107, 183, 98, 0.74);
}

/*.alert{
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-bottom: 500px;
}*/


.read-only-banner{
    position: fixed;
    top: 0;
    text-align: center;
    width: 100%;
    z-index:99;
}


.footerContentMain{
    display: flex;
    gap: 80px;
    padding-bottom: 20px;
    flex-wrap: nowrap;
}

.footerCompanyInfos{
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    gap: 35px;
}

.footerCompanyName{
    color: #FFF;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.footerProblemReportLink{
    color: #000;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-decoration-line: underline;
}


.footerProblemReportLink:hover{
    color:#FFF;
    transition: all .2s ease-in;
}


.footerLegalInfo{
    grid-area: 1 / 3 / 2 / 3;
    display:flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 5px;
    word-break: keep-all;
}

.footerLegalInfoItem{
    position: relative;
    text-decoration: none;
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.footerLegalInfoItem::before{
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background-color: #0C0C0C;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.3s ease;  
}

.footerLegalInfoItem:hover{
    color: #0C0C0C;
    transition: all .2s ease-in;
}

.footerLegalInfoItem:hover::before{
    transform: scaleX(1);
}

.footerCompanySocials{
    margin-left: auto;
    margin-right: 50px;
    grid-area: 1 / 5 / 2 / 5;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.footerCompanyMailHeading{
    margin-right: auto;
    color: #FFF;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}



.footerCompanyMail{
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.footerCompanySocialsDivider{
 width: 200px;
}

.footerSocialInfo{
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.footerSocialInfoItem:hover{
    filter: invert(100%);
    transition: all .2s ease-in-out;
}

.footerDescription{
    position: absolute;
    bottom: 0;
    padding:20px;
    color: #212121;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
}
}
