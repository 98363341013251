@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;600;700;800&display=swap');

.endedMain{
    padding: 0px 10px 0px 10px;
    font-family: 'montserrat', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    height: 100vh;
}

.endedImage{

}

.endedHeading{
    color: #474747;
    font-weight: 700;
    font-size: 28px;
}

.endedSubheading{
    text-align: center;
    color: #5b5b5b;
    font-weight: 600;
    font-size: 22px;
}

