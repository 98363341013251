@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;500;600;800&display=swap');

.Login{
    height: 100vh !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: 'Montserrat', 'sans-serif';
    background-color: var(--background-color);
}

.loginWrapper{
    background-color: var(--box-bg-color);
    border-radius: 15px;
    width: 550px !important;
    padding: 20px;
    border:var(--secondary-text-color) 1px solid;
    box-shadow: 0px 0px 15px rgb(169, 169, 169);
}

.loginFormHeading{
    color:var(--primary-text-color);
    font-size:18px;
    font-weight: 500;
}

.loginWrapperMain {
    margin-top: 20px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
}

.loginPgImage {
    border-radius: 12px;
}

.inviteMainTxt{
    padding:10px;
    color: var(--mid-text-color);
    font-size:50px;
    font-weight: 400;
    text-shadow: 3px 3px 4px var(--secondary-text-color);
    letter-spacing: 2px;
}

.inviteButtonWrapper{
    padding-top:5px;
}

.loginMainWidgetDiff{
    padding-top:10px;
    padding-bottom: 0px;
}

.loginViewPlTxt{
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--mid-text-color);
    font-size: 17px;
}

.loginViewPlTxt:hover{
    transition:  .2s linear;
    color: var(--primary-text-color);
    text-shadow: 3px 3px 4px var(--secondary-text-color);
}