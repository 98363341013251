:root {
    --primary-text-color:#6d6d6d;
    --mid-text-color:#7F7F7F;
    --box-sc-bg-color: #D9D9D9;
    --secondary-text-color:#bfbfbf;
    --background-color: #E3E3E3;
    --box-bg-color: #d6d6d6;
    --green-mint-primary:#98C2A8;
}

body {
     background-color: #FFF;
}

@media (min-width: 768px) {
    body {
        background-color: var(--background-color);
    } 
}
