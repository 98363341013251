.loginBtn{
    border:none;
    border-radius: 12px;
    background-color: var(--green-mint-primary);
    font-size: 35px;
    font-weight: 600;
    padding: 10px;
    cursor: pointer;
    color:#2b2b2b;
    padding-left: 90px;
    padding-right: 90px;
}

.loginBtn:hover{
    transition: all 0.2s ease-in-out;
    color:#ffffff;
    background-color: #2faf6f;
    border-color: #2faf6f;
    box-shadow: 2px 2px 15px rgba(107, 183, 98, 0.74);
}